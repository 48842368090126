import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useEffect } from 'react';
import { FaMicrophone } from "react-icons/fa";
import { FaStop } from "react-icons/fa";

export const Dictaphone = ({ text, setText, theme }) => {
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    // update text whenever transcript changes
    useEffect(() => {
        setText(transcript);
    }, [transcript]);

    if (!browserSupportsSpeechRecognition) {
        return <span></span>;
    }


    return (
        <div>
            {!listening && <FaMicrophone style={{ fontSize: '1.7rem', cursor: 'pointer' }} onClick={
                () => SpeechRecognition.startListening({ language: 'ar-SA' })
            } />
            }
            {listening && <FaStop style={{  fontSize: '1.7rem', cursor: 'pointer' }} onClick={SpeechRecognition.stopListening} />}
        </div>
    );
};

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams for accessing URL params
import ChatMessage from './ChatMessage';
import { Dictaphone } from './Extras/Dictaphone';
import { BsFillSendFill } from "react-icons/bs";
import { MdFileUpload } from "react-icons/md";
import { FaStar } from "react-icons/fa6";
import ClipLoader from "react-spinners/ClipLoader";
import { handleFileChange, handleSendMessage, fetchChatHistory } from '../requests';
import { IoIosSettings } from "react-icons/io";
import { IoIosRefresh } from "react-icons/io";
import { IoLanguage } from "react-icons/io5";
import { MdDarkMode } from "react-icons/md";
import { MdOutlineLightMode } from "react-icons/md";

export const saveSet = (setter, name, value) => {
  function newSetter(item) {
    setter(item);
    localStorage.setItem(name, JSON.stringify(item));
  }
  newSetter(value)
}


export const init = (key, defaultValue) => {
  const storedValue = localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : defaultValue;
};

function ChatInterface() {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const messagesEndRef = useRef(null);

  const [model, setModel] = useState('Llama');
  const [language, setLanguage] = useState('Arabic');

  const [msgLoading, setMsgLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const [showFeedback, setShowFeedback] = useState(true);
  const [showSettings, setShowSettings] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const [uploadLabel, setUploadLabel] = useState("Upload Knowledge");
  const fileInputRef = useRef(null);
  const formRef = useRef(null);

  const firstIfEnglish = (a, b) => language === 'English' ? a : b;

  const [justRetried, setJustRetried] = useState(false);
  const [rag, setRag] = useState(false);

  const { id } = useParams(); // Get the 'id' from the URL

  const [theme, setTheme] = useState(init('theme', 'light'));


  useEffect(() => {
    console.log('ID:', id);
    if (id) {
      const loadChatHistory = async () => {
        const chatHistory = await fetchChatHistory(id);
        if (chatHistory) {
          setMessages(chatHistory); // Set the retrieved chat history
        } else {
          console.error('Chat history could not be loaded.');
        }
      };

      loadChatHistory();
    }
  }, [id]);


  return (
    <div className="chat-container"
      style={{
        backgroundColor: theme === 'dark' ? 'rgba(21, 21, 21, 0.945)' : undefined,
        backgroundImage: theme === 'light' ? 'url(/background.png)' : undefined,
        backgroundSize: 'cover', // Ensure the image covers the entire background
        backgroundRepeat: 'no-repeat', // Prevent the image from repeating
      }}

    >
      {/* Initial Background */}
      {messages.length === 0 && <InitialBackground theme={theme} />}

      {/* Chatbot Header */}
      <ChatbotHeader theme={theme} language={language} />

      {/* Chatbox with messages */}
      <div className="chat-messages">
        {messages.map((message, index) => (
          <>
            <ChatMessage key={index} message={message} previousMessage={messages[index - 1]} language={language} showFeedback={showFeedback}
              handleSendMessage={handleSendMessage}
              theme={theme}
            />
            {message.isBot && (index === messages.length - 1 || index === messages.length - 2) && !justRetried && rag && <div> <button
              style={{
                display: 'flex', alignItems: 'center', gap: '0.5rem', borderRadius: '2rem', backgroundColor: (theme === 'dark') ? '#1d1d1d' : 'white', padding: '0.5rem 1.5rem', marginTop: '1rem', color: (theme === 'dark') ? 'white' : '#1d1d1d',
              }}
              onClick={() => { handleSendMessage(null, setInputText, messages[index - 1].text, messages, setMessages, setMsgLoading, model, language); setJustRetried(true); }}
            >
              <IoIosRefresh style={{ fontSize: '1.4rem', color: (theme === 'dark') ? 'white' : '#1d1d1d' }} />
              {firstIfEnglish("Retry ignoring sources", "حاول بلا مصادر")}
            </button></div>}
          </>
        ))}
        <div ref={messagesEndRef} />

      </div>

      {/* Chatbox input */}
      <ChatboxInput setInputText={setInputText} inputText={inputText} messages={messages}
        setMessages={setMessages} setMsgLoading={setMsgLoading} model={model} language={language}
        firstIfEnglish={firstIfEnglish}
        setLanguage={setLanguage}
        uploadLabel={uploadLabel}
        setUploadLabel={setUploadLabel}
        uploadLoading={uploadLoading}
        setUploadLoading={setUploadLoading}
        fileInputRef={fileInputRef}
        formRef={formRef}
        showSettings={showSettings}
        setShowSettings={setShowSettings}
        setJustRetried={setJustRetried}
        setRag={setRag}
        theme={theme}
        setTheme={setTheme}
      />

      {/* Bottom bar */}
      {showSettings && <BottomBar
        uploadLabel={uploadLabel}
        setUploadLabel={setUploadLabel}
        uploadLoading={uploadLoading}
        setUploadLoading={setUploadLoading}
        formRef={formRef}
        fileInputRef={fileInputRef}
        model={model}
        setModel={setModel}
        language={language}
        setLanguage={setLanguage}
        showFeedback={showFeedback}
        setShowFeedback={setShowFeedback}
        theme={theme}
      />}

    </div>
  );
}


function InitialBackground({ theme }) {

  return (
    <>
      {true && (
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
          }}
        >
          <img
            src="./logo-hyper.png"
            alt="ChatBot"
            style={{
              width: "80%",
              maxWidth: "900px",
              marginBottom: "0px",
              opacity: (theme == 'dark') ? "0.45" : "0.7",
            }}
          />
        </div>
      )}
    </>
  );
}

function ChatbotHeader({ theme, language }) {
  return (
    <div style={{
      backgroundColor: (theme == 'dark') ? "rgba(21, 21, 21, 0.975)" : "rgba(225, 225, 225, 0.925)", padding: '10px 15px',
      display: 'flex',
      borderBottom: (theme == "dark") ? "" : "1px solid #cccccc99",
      justifyContent: (language == 'Arabic') ? 'flex-end' : 'flex-start',
    }}>
      <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'transparent', flexDirection: (language == 'Arabic' ? 'row-reverse' : 'row') }}>
        <div style={{ position: 'relative' }}>
          <img src="llama.png" alt="ChatBot"
            className="user_img"
          />
          <span
            style={{
              position: 'absolute',
              height: '15px',
              width: '15px',
              backgroundColor: '#4cd137',
              borderRadius: '50%',
              bottom: '0.2em',
              right: '0.4em',
              border: '1.5px solid white',
              zIndex: 99,
            }}
          ></span>
        </div>
        <div
          style={{ color: (theme == 'dark') ? 'white' : 'black', fontFamily: 'Poppins', fontSize: '1.7rem', paddingLeft: '20px', paddingRight: '10px' }}>
          <span>{(language == 'Arabic') ? 'HyperGPT' : 'HyperGPT'}</span>
          <p
            style={{
              fontSize: '1.0rem',
              color: (theme == 'dark') ? '#cccccc77' : '#1d1d1d99',
              marginTop: '0rem',
              fontFamily: (language == 'Arabic') ? 'IBM Plex Sans Arabic' : 'Poppins',
              fontWeight: '500',
              textAlign: (language == 'Arabic') ? 'right' : 'left',
            }}
          >
            {(language == 'Arabic') ? '...هنا للمساعدة' : 'Ask me anything!'}
          </p>
        </div>
      </div>
    </div>
  );
}

function ChatboxInput({ setInputText, inputText, messages, setMessages, setMsgLoading, model, language,
  uploadLabel,
  setUploadLabel,
  setLanguage,
  firstIfEnglish,
  uploadLoading,
  setUploadLoading,
  fileInputRef,
  formRef,
  showSettings,
  setShowSettings,
  setJustRetried,
  setRag,
  theme,
  setTheme
}) {
  const override = {
    margin: "0rem 0rem 0rem 1rem",
  };
  return (
    <div style={{ width: '100%', display: 'flex', alignItems: 'center', backgroundColor: 'transparent' }}>
      <form id="messageArea" style={{ flexBasis: '100%', display: 'flex', backgroundColor: 'transparent', flexDirection: 'row', justifyContent: 'space-around', }} onSubmit={(e) => handleSendMessage(e, setInputText, inputText, messages, setMessages, setMsgLoading, model, language)}
      >
        <input
          id="text"
          name="msg"
          style={{
            width: '99%', padding: '10px', paddingRight: '7rem', paddingLeft: '13rem',
            backgroundColor: (theme == 'dark') ? "rgba(21, 21, 21, 1.0)" : "rgba(215, 215, 215, 0.925)",
            fontSize: '1.3rem', height: '3rem', textAlign: (language === 'English') ? 'left' : 'right', borderRadius: '14px', outline: 'none', border: 'none',
            color: (theme == 'dark') ? 'white' : '#1d1d1d',
            position: 'relative'
          }}
          placeholder={(language === 'English') ? "What is your question..." : "...ما هو سؤالك"}
          autoComplete="off"
          className="form-control type_msg"
          required
          onChange={(e) => setInputText(e.target.value)}
          value={inputText}
        />
        <div style={{ color: (theme == 'dark') ? 'white' : '#1d1d1d', fontSize: '1.4rem', position: 'absolute', right: '4rem', cursor: 'pointer', zIndex: '199', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '0.8rem' }}>
          <Dictaphone text={inputText} setText={setInputText} />
          <BsFillSendFill onClick={(e) => { handleSendMessage(e, setInputText, inputText, messages, setMessages, setMsgLoading, model, language); setJustRetried(false) }} />
        </div>
        <div style={{ color: (theme == 'dark') ? 'white' : '#1d1d1d', fontSize: '1.4rem', position: 'absolute', left: '4rem', cursor: 'pointer', zIndex: '199', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '0.8rem' }}>
          {theme == 'dark' && <MdDarkMode onClick={() => saveSet(setTheme, 'theme', 'light')} style={{ fontSize: '1.7rem' }} />}
          {theme == 'light' && <MdOutlineLightMode onClick={() => saveSet(setTheme, 'theme', 'dark')} style={{ fontSize: '1.7rem' }} />}
          <IoIosSettings onClick={() => setShowSettings(!showSettings)} style={{ fontSize: '1.7rem' }} />
          <IoLanguage onClick={() => setLanguage(firstIfEnglish('Arabic', 'English'))} style={{ fontSize: '1.7rem' }} />

          <form
            ref={formRef}
            className="input-group mt-2"
            encType="multipart/form-data"
            method="POST"
            style={{ backgroundColor: 'transparent', padding: '20px 0px' }}
          >          <label htmlFor="files" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '0.8rem' }}>
              {!uploadLoading && <MdFileUpload style={{ fontSize: '1.7rem' }} />}
              {uploadLoading && <ClipLoader cssOverride={override} color={(theme == 'dark') ? '#ccc' : '#1d1d1d'} loading={true} size={20} />}
            </label>
            <input
              style={{ display: 'none' }}
              type="file"
              id="files"
              name="files"
              className="form-control-file"
              multiple
              ref={fileInputRef}
              onChange={(event) => { handleFileChange(event, setUploadLabel, setUploadLoading, formRef, fileInputRef); setRag(true); }}
            />
          </form>
        </div>
      </form>
    </div>
  )
}

const BottomBar = ({
  uploadLabel,
  setUploadLabel,
  uploadLoading,
  setUploadLoading,
  formRef,
  fileInputRef,
  model,
  setModel,
  language,
  setLanguage,
  showFeedback,
  setShowFeedback,
  theme
}) => {
  const override = {
    margin: "0rem 0rem 0rem 1rem",
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-1rem', padding: '1rem 4rem', backgroundColor: (theme == 'light') ? "transparent" : 'transparent', alignItems: 'center', flexWrap: 'wrap' }}>

      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '1rem' }}>
        <select
          style={{ backgroundColor: 'transparent', color: (theme == 'dark') ? '#ccc' : 'black', borderColor: (theme == 'dark') ? '#ccc' : '#1d1d1d77', minWidth: '25vw', borderRadius: '5px', padding: '0rem 1rem', height: '3rem' }}
          id="modelSelect"
          className="form-control mt-2"
          value={model}
          onChange={(e) => { setModel(e.target.value); }}
        >
          <option value="GPT">GPT</option>
          <option value="Llama">Llama</option>

          <option value="Gemini">Gemini</option>
          <option value="Aya-Expanse">Aya-Expanse</option>
          <option value="Qwen">Qwen</option>
        </select>
        {/* <select
          style={{ backgroundColor: 'transparent', color: '#ccc', borderColor: '#ccc', minWidth: 'max(15vw, 150px)', borderRadius: '5px', padding: '0rem 1rem', height: '3rem' }}
          className="form-control mt-2"
          onChange={(e) => { setLanguage(e.target.value); }}
        >
          <option value="Arabic">Arabic</option>
          <option value="English">English</option>
        </select> */}
        <div>
          <FaStar style={{ color: (!showFeedback) ? ((theme == 'dark') ? '#3d3d3d' : '#d3d3d3') : ((theme == 'dark') ? 'white' : 'black'), fontSize: '1.7rem', cursor: 'pointer' }} onClick={() => setShowFeedback(!showFeedback)} />
        </div>
      </div>

    </div>
  )
}

export default ChatInterface;